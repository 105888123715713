import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const muselyrique = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Muse lyrique" />
    <h3 className='underline-title'>Muse lyrique</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je t'évoque Polymnie du Torride<br />
        Descends du mont sacré d'Amba<br />
        Ferme tes yeux aux palmiers du mont voisin<br />
        Palmiers chargés de fruits outrageux d'Onassis<br />
      </p>
      <p>
        Dévale torse nu du Kahuzi-le-sanctuaire<br />
        N'envie point les tendres bambous d'anthropoïdes<br />
        Bambous du Centre d'Eden des parents bibliques<br />
      </p>
      <p>
        Gravis la pente abrupte de la féerique montagne<br />
        Ne t'essouffle pas de raide Virunga<br />
        C'est le chemin de l'air altier du sommet<br />
      </p>
      <p>
        Polymnie de mon coeur, je t'invoque<br />
        Demeure la Muse sans artifice<br />
        Qui point avec le jour<br />
        Qui éclate avec le soleil<br />
        Qui s'éteint avec les vêpres<br />
      </p>
      <p>
        Sois la Muse authentique<br />
        Qui vole avec le vent<br />
        Qui bondit avec la vague<br />
        Qui varie avec les saisons<br />
      </p>
      <p>
        Muse ma féale<br />
        Jamais ne deviens vénale<br />
        Tu t'avilirais à la racoleuse<br />
        Tu te ravalerais à la soiffarde<br />
      </p>
      <p>
        Ne te déprécie pas comme les monnaies<br />
        Tu ne vivrais que la vie d'une rose<br/>
        Ephémère d'une matinée<br />
        Et tu fanerais à jamais<br />
      </p>
      <p>
        Muse personnifiée Muse personnelle<br />
        Je te connais Muse Lyrique<br />
        Tu n'inspires point d'épithètes louangeuses<br />
        Tu ingores encore les vocables laudatifs<br />
      </p>
      <p>
        Je voudrais t'adorer Muse chérie<br />
        Si j'étais Babylonien<br />
        Je t'encenserais si j'étais thuriféraire<br />
        Je te vanterais si j'étais dithyrambiste<br />
      </p>
      <p>
        Muse authentique<br />
        <span className="par-starting"></span>- enfant candide<br />
        <span className="par-starting"></span>- rebelle inexpugnable<br />
        <span className="par-starting"></span>- hirondelle aérienne<br />
        <span className="par-starting"></span>- telapia lacustre<br />
      </p>
      <p>
        Muse authentique<br />
        <span className="par-starting"></span>- frimas du Septentrion<br />
        <span className="par-starting"></span>- canicule de l'Equateur<br />
        <span className="par-starting"></span>- giboulé de Bukavu<br />
        <span className="par-starting"></span>- vent austral<br />
      </p>
      <p>
        AU - THEN - TI - QUE<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default muselyrique
